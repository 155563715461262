<template>
  <div class="login">
    <!-- 登录表单 -->
    <div class="form">
      <div class="title">系统登录</div>
      <el-form
        :model="loginForm"
        :rules="rules"
        ref="loginForm"
        label-width="80px"
        class="loginForm"
      >
        <el-form-item label="用户名" prop="username">
          <el-input
            v-model.trim="loginForm.username"
            placeholder="请输入用户名"
          ></el-input>
        </el-form-item>

        <el-form-item label="密码" prop="parssword">
          <el-input
            v-model.trim="loginForm.parssword"
            type="password"
            placeholder="请输入密码"
          ></el-input>
        </el-form-item>
        <el-button type="primary" @click="submit"> 登录 </el-button>
      </el-form>
    </div>
  </div>
</template>

<script>
import { login } from "../../api/user"
export default {
  name: "Login",
  data() {
    return {
      // 登录表单
      loginForm: {
        username: "",
        parssword: "",
      },
      // 校验
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        parssword: [
          { required: true, message: "请输入密码", trigger: "change" },
        ],
      },
    }
  },

  methods: {
    // 提交
    submit() {
      this.$refs.loginForm.validate(async (valid) => {
        // 校验成功跳转页面
        if (valid) {
          // console.log("请求接口并跳转", this.loginForm)
          const res = await login({
            userName: this.loginForm.username,
            password: this.loginForm.parssword,
          })
          console.log(res)
          if (res.code === 200) {
            const { id, token } = res.data
            window.sessionStorage.setItem("token", token)
            window.sessionStorage.setItem("userID", id)
            this.$router.push("/home")
            this.$message({
              type: "success",
              message: "登录成功",
            })
          }
          if (res.code !== 200) {
            return this.$message({
              type: "danger",
              message: "登录失败",
            })
          }
        } else {
          console.log("error submit!!")
          return false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.login {
  height: 100%;
  color: #fff;
  position: relative;
  background-color: #2d3a4b;

  // 表单居中
  .form {
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0 2px 12px 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .title {
    width: 100%;
    height: 50px;
    color: #606266;
    font-size: 28px;
    text-align: center;
    margin-bottom: 22px;
    letter-spacing: 8px;
    border-bottom: 1px solid #ccc;
  }

  .loginForm {
    width: 450px;
    padding: 20px;

    .el-button {
      width: 100%;
    }
  }
}
</style>
